var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "preview-page"
  }, [_c('Pages', {
    attrs: {
      "preview": ""
    }
  }, [_vm.form.titlePage.show ? _c('Page', {
    attrs: {
      "title": _vm.form.titlePage.title || 'Consumption Report',
      "sub-title": _vm.form.titlePage.subTitle || 'Cologne Retail Park',
      "description": "March 2023 - March 2024"
    }
  }) : _vm._e(), _vm._l(_vm.form.pages, function (page, pageIdx) {
    return _c(page.landscape ? 'PageLandscape' : 'Page', {
      key: pageIdx,
      tag: "component",
      attrs: {
        "no-header": ""
      }
    }, [_c('div', {
      staticClass: "etn-report"
    }, [page.header.show ? _c('div', {
      staticClass: "bg-lighter rounded-md mb-4 p-4"
    }, [_c('div', {
      staticClass: "row align-items-middle"
    }, [_c('div', {
      staticClass: "col-md-4"
    }, [_c('PageHeader', {
      scopedSlots: _vm._u([{
        key: "top",
        fn: function () {
          return [_vm._v("March 2023 to March 2024")];
        },
        proxy: true
      }, {
        key: "title",
        fn: function () {
          return [_vm._v(_vm._s(page.header.title || 'Consumption Report'))];
        },
        proxy: true
      }, {
        key: "bottom",
        fn: function () {
          return [_vm._v(_vm._s(page.header.subTitle || 'Berlin Retail Park'))];
        },
        proxy: true
      }], null, true)
    })], 1), _c('div', {
      staticClass: "col-md-6"
    }, [_c('div', [_c('PropertyListItem', {
      staticClass: "mb-2 font-w600",
      attrs: {
        "col-size": "col-md-4",
        "title": "Company"
      }
    }, [_vm._v("Retail Parks Management Limited")]), _c('PropertyListItem', {
      staticClass: "font-w600",
      attrs: {
        "col-size": "col-md-4",
        "title": "Site"
      }
    }, [_vm._v("Berlin Retail Park")])], 1)]), _c('div', {
      staticClass: "col-md-2 text-right"
    }, [_c('img', {
      staticClass: "logo",
      attrs: {
        "src": _vm.$auth.company.settings.reportLogoUrl || _vm.$auth.company.settings.logoUrl
      }
    })])])]) : _vm._e(), _vm._l(page.components, function (component, componentIdx) {
      return _c('div', {
        key: componentIdx,
        staticClass: "mb-4"
      }, [component.type === 'text' ? _c('TextBox', {
        scopedSlots: _vm._u([{
          key: "title",
          fn: function () {
            return [_vm._v(_vm._s(component.title))];
          },
          proxy: true
        }], null, true)
      }, [[_vm._v(_vm._s(component.content))]], 2) : _vm._e(), component.type === 'table' ? _c(_vm.tableComponents[component.component], _vm._b({
        tag: "component",
        attrs: {
          "data": _vm.exampleData.tables[component.component].data,
          "prev-data": _vm.exampleData.tables[component.component].prevData,
          "period": _vm.exampleData.tables[component.component].period,
          "unit": "kWh"
        }
      }, 'component', _vm.exampleData.tables[component.component].props, false)) : _vm._e(), component.type === 'chart' ? _c(_vm.chartComponents[component.component], _vm._b({
        tag: "component",
        style: `height: ${_vm.exampleData.charts[component.component].height || '400px'}`,
        attrs: {
          "data": _vm.exampleData.charts[component.component].data,
          "prev-data": _vm.exampleData.charts[component.component].prevData,
          "period": _vm.exampleData.charts[component.component].period,
          "unit": "kWh"
        }
      }, 'component', _vm.exampleData.charts[component.component].props, false)) : _vm._e()], 1);
    })], 2)]);
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }