var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "type",
      "label": "Type",
      "type": "select",
      "options": _vm.componentTypes
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _vm.form.type === 'text' ? _c('div', [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "title",
      "label": "Title",
      "type": "text"
    },
    model: {
      value: _vm.form.title,
      callback: function ($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  }), _c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "content",
      "label": "Content",
      "type": "textarea"
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1) : _vm._e(), _vm.form.type === 'table' ? _c('div', [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "component",
      "label": "Component",
      "type": "select",
      "options": _vm.tableComponents
    },
    model: {
      value: _vm.form.component,
      callback: function ($$v) {
        _vm.$set(_vm.form, "component", $$v);
      },
      expression: "form.component"
    }
  })], 1) : _vm._e(), _vm.form.type === 'chart' ? _c('div', [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "component",
      "label": "Component",
      "type": "select",
      "options": _vm.chartComponents
    },
    model: {
      value: _vm.form.component,
      callback: function ($$v) {
        _vm.$set(_vm.form, "component", $$v);
      },
      expression: "form.component"
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }