var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "bg-light rounded-md p-4 mb-3"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-md-3 text-right"
  }, [_c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.header.show,
      expression: "form.header.show"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "id": `form-header-show${_vm.index}`,
      "type": "checkbox",
      "name": "form-header-show"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.header.show) ? _vm._i(_vm.form.header.show, null) > -1 : _vm.form.header.show
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.form.header.show,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form.header, "show", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form.header, "show", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form.header, "show", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": `form-header-show${_vm.index}`
    }
  }, [_vm._v("Include")])])])]), _vm.form.header.show ? _c('div', {
    staticClass: "row border-top pt-3 mt-3"
  }, [_c('div', {
    staticClass: "col-xl-9"
  }, [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": `form-header-title${_vm.index}`,
      "label": "Title",
      "type": "text"
    },
    model: {
      value: _vm.form.header.title,
      callback: function ($$v) {
        _vm.$set(_vm.form.header, "title", $$v);
      },
      expression: "form.header.title"
    }
  }), _c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": `form-header-subTitle${_vm.index}`,
      "label": "Sub-title",
      "type": "text"
    },
    model: {
      value: _vm.form.header.subTitle,
      callback: function ($$v) {
        _vm.$set(_vm.form.header, "subTitle", $$v);
      },
      expression: "form.header.subTitle"
    }
  })], 1)]) : _vm._e()]), _c('div', {
    staticClass: "bg-light rounded-md p-4 mb-3"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_vm._m(1), _c('div', {
    staticClass: "col-md-3 text-right"
  }, [_c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.landscape,
      expression: "form.landscape"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "id": `form-landscape${_vm.index}`,
      "type": "checkbox",
      "name": "form-landscape"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.landscape) ? _vm._i(_vm.form.landscape, null) > -1 : _vm.form.landscape
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.form.landscape,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "landscape", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "landscape", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "landscape", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": `form-landscape${_vm.index}`
    }
  }, [_vm._v("Landscape")])])])])]), _vm._l(_vm.form.components, function (component, componentIdx) {
    return _c('div', {
      key: componentIdx,
      staticClass: "bg-light rounded-md p-4 mb-3"
    }, [_c('div', {
      staticClass: "row align-items-center"
    }, [_c('div', {
      staticClass: "col"
    }, [_c('h5', {
      staticClass: "mb-1"
    }, [_vm._v("Component " + _vm._s(componentIdx + 1))]), _c('p', {
      staticClass: "font-size-sm mb-0"
    })]), _c('div', {
      staticClass: "col-md-1 text-right"
    }, [_c('button', {
      staticClass: "btn btn-sm btn-link text-danger font-w600",
      on: {
        "click": function ($event) {
          _vm.modals.deleteComponent = componentIdx;
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-times fa-lg"
    })])])]), _c('div', {
      staticClass: "border-top pt-3 mt-3"
    }, [_c('ComponentForm', {
      attrs: {
        "value": component,
        "index": componentIdx
      },
      on: {
        "input": function (data) {
          return _vm.onUpdateComponent(componentIdx, data);
        }
      }
    })], 1)]);
  }), _c('div', {
    staticClass: "row mb-3"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('button', {
    staticClass: "btn btn-dark btn-block",
    on: {
      "click": _vm.onAddComponent
    }
  }, [_c('i', {
    staticClass: "fa fa-plus mr-1"
  }), _vm._v(" Add Component")])])]), _c('ConfirmModal', {
    attrs: {
      "open": _vm.modals.deleteComponent !== false,
      "title": "Delete Component",
      "text": "Are you sure you want to delete this component?"
    },
    on: {
      "submit": _vm.onDeleteComponent,
      "close": function ($event) {
        _vm.modals.deleteComponent = false;
      }
    }
  })], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col"
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v("Page Header")]), _c('p', {
    staticClass: "font-size-sm mb-0"
  }, [_vm._v("A box at the top of the page with some information. Normally used when no title page is used.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col"
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v("Page Orientation")]), _c('p', {
    staticClass: "font-size-sm mb-0"
  }, [_vm._v("Some charts may display better in Landscape.")])]);

}]

export { render, staticRenderFns }